export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },

  {
    title: 'Invoices',
    icon: 'FileTextIcon',
    Permission: 'invoice_page_menu',
    children: [
      {
        title: 'Add',
        route: 'invoice-add',
        icon: 'UserPlusIcon',
        Permission: 'invoice_add',
      },
      {
        title: 'List',
        route: 'invoice-list',
        icon: 'ListIcon',
        Permission: 'invoice_page_menu',
      },
      {
        title: 'Edit',
        route: 'invoice-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: 'invoice_edit',
      },
    ],
  },

  {
    title: 'Users',
    icon: 'UserIcon',
    Permission: 'user_page_menu',
    children: [
      {
        title: 'Add',
        route: 'user-add',
        icon: 'UserPlusIcon',
        Permission: 'user_add_btn',
      },
      {
        title: 'List',
        route: 'user-list',
        icon: 'ListIcon',
        Permission: 'user_page_menu',
      },
      {
        title: 'Edit',
        route: 'user-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: 'user_page_menu',
      },
    ],
  },

  {
    title: 'Employees',
    icon: 'UsersIcon',
    Permission: 'employees_page_menu',
    children: [
      {
        title: 'Employee Add',
        route: 'employee-add',
        icon: 'UserPlusIcon',
        Permission: 'employees_add_btn',
      },

      {
        title: 'Employee List',
        route: 'employee-list',
        icon: 'ListIcon',
        Permission: 'employees_page_menu',
      },

      {
        title: 'Report',
        route: 'employee-report',
        icon: 'EditIcon',
        Permission: 'employee_report_menu',
      },

      {
        title: 'Edit',
        // route: 'employee-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: 'employees_page_menu',
      },
    ],
  },

  {
    title: 'Teams',
    icon: 'UsersIcon',
    Permission: 'employees_page_menu',
    children: [
      {
        title: 'Team Add',
        route: 'team-add',
        icon: 'UserPlusIcon',
        Permission: 'employees_add_btn',
      },

      {
        title: 'Team List',
        route: 'team-list',
        icon: 'ListIcon',
        Permission: 'employees_page_menu',
      },

      {
        title: 'Edit',
        // route: 'employee-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: 'employees_page_menu',
      },
    ],
  },

  {
    title: 'Clients',
    icon: 'UserIcon',
    Permission: 'client_page_menu',
    children: [
      {
        title: 'Add',
        route: 'client-add',
        icon: 'UserPlusIcon',
        Permission: 'client_add_btn',
      },
      {
        title: 'List',
        route: 'client-list',
        icon: 'ListIcon',
        Permission: 'client_page_menu',
      },
      {
        title: 'Edit',
        route: 'client-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: 'client_page_menu',
      },
    ],
  },

  {
    title: 'Vehicles',
    icon: 'TruckIcon',
    Permission: 'vehicle_page_menu',
    children: [
      {
        title: 'Add',
        route: 'vehicle-add',
        icon: 'UserPlusIcon',
        Permission: 'vehicle_add_btn',
      },
      {
        title: 'List',
        route: 'vehicle-list',
        icon: 'ListIcon',
        Permission: 'vehicle_page_menu',
      },
      {
        title: 'Edit',
        route: 'vehicle-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: 'vehicle_page_menu',
      },
    ],
  },

  // {
  //   title: 'Hotels',
  //   icon: 'HomeIcon',
  //   Permission: 'hotel_page_menu',
  //   children: [
  //     {
  //       title: 'Add',
  //       route: 'hotel-add',
  //       icon: 'UserPlusIcon',
  //       Permission: 'hotel_add_btn',
  //     },
  //     {
  //       title: 'List',
  //       route: 'hotel-list',
  //       icon: 'ListIcon',
  //       Permission: 'hotel_page_menu',
  //     },
  //     {
  //       title: 'Edit',
  //       route: 'hotel-edit',
  //       icon: 'EditIcon',
  //       disabled: true,
  //       Permission: 'hotel_page_menu',
  //     },
  //   ],
  // },

  {
    title: 'Settings',
    icon: 'SettingsIcon',
    Permission: 'settings_page_menu',
    route: 'settings',
  },
];
